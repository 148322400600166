import React, { useState } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import IssueModal from './IssueModal'

const IssuesContainer = ({ issues, searchValue, handleSearch }) => {
    const [showIssueModal, setShowIssueModal] = useState(false);
    const [modalIssue, setModalIssue] = useState(null);

    const handleCloseModal = () => setShowIssueModal(false);

    const handleShowModal = (e) => {
        setModalIssue(issues.filter(issue => issue.id === e.id));
        setShowIssueModal(true);
    };

    return (
        <>
            <Container fluid>
                <h2 className="text-center text-danger fw-bold fs-1 my-2">Issues</h2>
                {
                    !issues ? <h3 className="text-center text-danger">No Issues Found</h3>
                        : <Container>
                            <ListGroup>
                                {
                                    issues.filter((issues) => {
                                        if (searchValue === "") {
                                            return issues;
                                        }
                                        return issues.title.toLowerCase().includes(searchValue.toLowerCase());
                                    }).map(issue => (
                                        <ListGroup.Item
                                            key={issue.id}
                                            onClick={() => handleShowModal(issue)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h4 className="fw-bold">{issue.title}</h4>
                                                    <p
                                                        className="text-secondary fw-bold">
                                                        Created At : {Date(issue.created_at).slice(0, 15)} <br />
                                                        <span>Created By : <span className="text-primary">{issue.user.login}</span> </span>
                                                    </p>
                                                </div>
                                                <div>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => handleShowModal(issue)}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        </Container>
                }

                {
                    showIssueModal ? <IssueModal modalissue={modalIssue} show={handleShowModal} onHide={handleCloseModal} />
                        : null
                }
            </Container>
        </>
    )
}

export default IssuesContainer