import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const IssueModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="text-warning fs-2">
            Created By &nbsp;
            <a
              href={props.modalissue[0].user.html_url}
              className="fw-bold text-dagner"
              style={{textDecoration: "none"}}
              target="_blank"
            >
              {props.modalissue[0].user.login}
              &nbsp;
              <img
                src={props.modalissue[0].user.avatar_url}
                alt="user"
                style={{ width: "40px", borderRadius: "50%" }}
              />
            </a>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-primary fs-2'>{props.modalissue[0].title}</h4>
        <p className='fs-5'>
          {props.modalissue[0].body}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default IssueModal