import React, { useState, useEffect } from 'react'
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/NavBar';
import IssuesContainer from './components/IssuesContainer';

const App = () => {
  const [issues, setIssues] = useState(null)
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const fetchIssues = async () => {
      const res = await fetch("https://api.github.com/repos/PHP-FFMpeg/PHP-FFMpeg/issues?per_page=100")
      const data = await res.json()
      setIssues(data)
    }
    fetchIssues()
  }, [])

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  }

  return (
    <>
      <NavBar handleSearch={handleSearch} />
      <IssuesContainer issues={issues} searchValue={searchValue} handleSearch={handleSearch} />
    </>
  )
}

export default App