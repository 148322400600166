import React from 'react'
import { Navbar, Container, Form } from 'react-bootstrap'

const NavBar = ({handleSearch}) => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand>
                    <h4 className="text-primary">
                        PHP-FFMpeg/PHP-FFMpeg &nbsp;
                        <span className="text-danger">
                            Issues
                        </span>
                    </h4>
                </Navbar.Brand>
                <Form className="d-flex">
                    <Form.Control
                        type="search"
                        placeholder="Search Issues" 
                        className="me-2"
                        aria-label="Search"
                        onChange={handleSearch}
                    />
                </Form>
            </Container>
        </Navbar>
    )
}

export default NavBar